/*  
    1. banner
        a. banner info
        b. banner image
*/

/* //////////////////////////////////////////////// */
// 1. banner

.banner {
    width: min(1440px, 100%);
    height: 267px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: $cl-black;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: $gr-blue;
        z-index: 2;
    }

    /* ================================================ */
    // a. banner info

    &__info {
        z-index: 3;
        width: min(700px, 100% - 40px);

        h1 {
            font-family: "Gotham Rounded A", "Gotham Rounded B", sans-serif;
            font-style: normal;
            font-weight: 325;
            font-size: $ft-title;
            line-height: 130%;
            color: $cl-white;
            padding: 0;
            margin: 0;

            @media screen and (min-width: $tablet) {
                font-size: $ft-title;
            }
        }

        @media screen and (min-width: $tablet) {
            margin-left: 80px;
        }
    }

    // end banner info
    /* ================================================ */

    /* ================================================ */
    // b. banner image

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 1;
    }

    // end banner image
    /* ================================================ */

    @media screen and (min-width: $tablet) {
        height: 360px;
        justify-content: flex-start;
    }
}

// end banner
/* //////////////////////////////////////////////// */
