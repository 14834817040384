/*
    1. quicklinks
        a. section title
        b. quicklinks inner 
        c. quicklinks wrapper
        d. link
*/

/* //////////////////////////////////////////////// */
// 1. quicklinks

.page-quicklinks {
    width: 100%;
    padding-bottom: 60px;
    margin: 0 auto;

    /* ================================================ */
    // a. section title

    h2.page-quicklinks__title {
        font-style: normal;
        font-weight: 325;
        font-size: $ft-title;
        color: $cl-royal-blue;
        font-family: "Gotham Rounded A", "Gotham Rounded B", sans-serif;
        margin: 0;
        margin-bottom: 45px;
        line-height: 130%;

        @media screen and (min-width: $tablet) {
            line-height: 40px;
        }
    }

    // end section title
    /* ================================================ */

    /* ================================================ */
    // b. quicklinks inner

    &__inner {
        display: grid;
        gap: 20px;
        justify-items: center;

        @media screen and (min-width: $tablet) {
            grid-template-columns: repeat(2, minmax(200px, 1fr))
        }
    }

    // end quicklinks inner
    /* ================================================ */


    /* ================================================ */
    // c. quicklinks wrapper

    &__link-wrapper {
        width: 100%;
        border-radius: 10px;
        background-color: $cl-light-grey;
        display: flex;
        align-items: center;
        position: relative;
        font-family: "Gotham A", "Gotham B", sans-serif;
    }

    // end quicklinks wrapper
    /* ================================================ */

    /* ================================================ */
    // d. link

    &__link {
        padding: 28px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        color: $cl-dark-blue;
        font-weight: 500;
        font-size: 18px;
        gap: 10px;

        &:is(:focus, :active) {
            border-radius: 10px;
            outline-offset: 0.2rem;
            color: $cl-dark-blue;
        }

        &:is(:hover) {
            color: $cl-dark-blue;
            text-decoration: underline;
        }
    }

    // end link
    /* ================================================ */
}

// end quicklinks
/* //////////////////////////////////////////////// */
