/*
    1. filter panel
        a. panel inner content
        b. panel header
        c. panel body
        d. panel footer

    2. panel is opened
*/

/* //////////////////////////////////////////////// */
// 1. filter panel

.filter-panel {
    position: absolute;
    width: 330px;
    left: -330px;
    height: 100%;
    background-color: $cl-white;
    display: flex;
    flex-direction: column;
    z-index: 3;
    transition: .3s;
    opacity: 0;
    $filter-root: &;

    @media screen and (min-width: $tablet) {
        width: 375px;
        left: -375px;
    }

    // filter panel main title
    h2.filter-panel__title {
        font-size: $ft-sub-title;
        margin: 0;
        margin-top: 8px;
        color: $cl-burnt-orange;
    }

    // filter panel category titles
    h3.filter-panel__sub-title {
        font-size: $ft-sub-sub-title;
        margin-top: 10px;
        margin-bottom: 16px;
        color: $cl-dark-grey;
        font-size: $ft-text-static;
        font-weight: bold;
    }

    /* ================================================ */
    // a. panel inner content

    &__inner {
        display: none;
        padding: 16px;
        box-sizing: border-box;
        overflow: auto;
        height: calc(100% - 80px);
        transition: .3s;
    }

    // end panel inner content
    /* ================================================ */

    /* ================================================ */
    // b. panel header

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 24px;
        font-family: "Gotham Rounded A", "Gotham Rounded B", sans-serif;
    }

    &__close {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'%3E%3Cpath d='M17.1663 2.47831L15.5213 0.833313L8.99967 7.35498L2.47801 0.833313L0.833008 2.47831L7.35467 8.99998L0.833008 15.5216L2.47801 17.1666L8.99967 10.645L15.5213 17.1666L17.1663 15.5216L10.6447 8.99998L17.1663 2.47831Z' fill='%23C05131'/%3E%3C/svg%3E");
        background-position: center;
        background-size: cover;
        width: 16px;
        height: 16px;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        margin-left: auto;
        border-radius: 0.15rem;

        &:focus:not(:focus-visible) {
            outline: none;
        }

        &:is(:focus) {
            outline: 0.2rem solid $cl-black;
            outline-offset: 0.2rem;
        }
    }

    // end panel header
    /* ================================================ */

    /* ================================================ */
    // c. panel body

    // filter sections
    &__section {
        border-top: 1px solid $cl-light-grey-accent;
    }

    &__listings,
    &__extra-filters {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        transition: max-height .6s;

        @media screen and (min-width: $tablet) {
            gap: 20px;
            margin-top: 15px;
        }
    }

    &__list-fully-selectable {
        padding-left: 20px;
    }

    &__input-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 21px;
        font-size: 16px;
        color: $cl-dark-grey;
        font-style: normal;

        &:hover {
            text-decoration: underline;
        }

        input {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
        }

        input:checked ~ #{$filter-root}__faux-checkbox::before {
            opacity: 1;
        }

        &:focus-within {
            outline: 0.2rem solid $cl-black;
            outline-offset: 0.2rem;
            border-radius: 0.1rem;
        }

        @media screen and (min-width: $tablet) {
            gap: 16px;
        }
    }

    &__faux-checkbox {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        border: 1px solid $cl-dark-grey;
        position: relative;

        &::before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='12' viewBox='0 0 16 12' fill='none'%3E%3Cpath d='M5.50001 9.47499L2.02501 5.99999L0.841675 7.17499L5.50001 11.8333L15.5 1.83333L14.325 0.658325L5.50001 9.47499Z' fill='white'/%3E%3C/svg%3E");
            background-size: 75%;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            width: calc(100% - 5px);
            height: calc(100% - 5px);
            background-color: $cl-royal-blue;
            border-radius: 1px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: .2s;
        }

        @media screen and (min-width: $tablet) {
            width: 32px;
            height: 32px;
        }
    }

    &__show-more {
        color: $cl-burnt-orange;
        background-color: transparent;
        border: none;
        margin-top: 18px;
        margin-bottom: 25px;
        display: block;
        width: 100%;
        text-align: left;
        padding: 0;

        &:is(:focus, :active) {
            outline: 0.2rem solid $cl-black;
            outline-offset: 0.2rem;
            border-radius: 0.1rem;
        }
    }

    &__extra-filters {
        max-height: 0;
        overflow: hidden;
        transition: max-height .6s;
        display: none;
    }

    &__extra-filters[data-toggled="true"] {
        max-height: 100rem;
        transition: max-height 1s;
        display: flex;
        overflow: unset;
    }

    // end panel body
    /* ================================================ */

    /* ================================================ */
    // d. panel footer

    &__footer {
        height: 80px;
        flex-shrink: 0;
        border-top: 1px solid $cl-light-grey-accent;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }

    &__footer-controls {
        display: none;
        padding: 0 21px;
        align-items: center;
        width: 100%;
        gap: 23px;

        @media screen and (min-width: $tablet) {
            button:first-of-type {
                margin-right: auto;
            }
        }
    }

    // end panel footer
    /* ================================================ */
}

// end filter panel
/* //////////////////////////////////////////////// */

/* //////////////////////////////////////////////// */
// 2. panel is opened

.map-module__wrapper[data-filters-open="true"] {
    .filter-panel {
        left: 0;
        opacity: 1;
        transition: .3s;

        &__inner {
            display: block;
        }

        &__backdrop {
            opacity: 1;
        }

        &__footer-controls {
            display: flex;
        }
    }
}

// end panel is opened
/* //////////////////////////////////////////////// */
