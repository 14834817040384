/*
    1. video modal
        a. modal inner
        b. video iframe
        c. modal header
        d. close modal
        e. video iframe container
        f. video info container
        g. video modal title
        h. video modal transcritpion link
*/
/* //////////////////////////////////////////////// */
// 1. video modal

.video-modal {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: $tr-black;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    z-index: 9999;

    /* ================================================ */
    // a. modal inner

    &__modal-inner {
        width: calc(100% - 10%);
        position: relative;
        background-color: #000;
        display: flex;
        flex-direction: column;

        /* ================================================ */
        // b. video iframe

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;

            @media screen and (max-height: 800px) {
                height: 90%;
            }
        }

        // end video iframe
        /* ================================================ */

        @media screen and (max-height: 800px) {
            position: absolute;
            top: 0;
        }
    }

    // end modal inner
    /* ================================================ */

    /* ================================================ */
    // c. modal header

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 40px;
    }

    // end modal header
    /* ================================================ */

    /* ================================================ */
    // d. close modal

    &__close {
        background-color: transparent;
        border: none;
        width: 26px;
        height: 26px;
        margin-right: 0.5rem;

        svg {
            width: 100%;
            height: 100%;
            fill: $cl-white;
        }
    }

    // end close modal
    /* ================================================ */

    /* ================================================ */
    // e. video iframe container

    &__video-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;    

        @media screen and (min-width: $desktop) {
            padding-top: 77vh;    
        }
    }

    // end video iframe container
    /* ================================================ */

    /* ================================================ */
    // f. video info container

    &__info {
        padding: 0 2rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: $cl-white;

        p {
            margin: 0;
            color: $cl-white;
        }
    }

    // end video info container
    /* ================================================ */

    /* ================================================ */
    // g. video modal title

    &__title {
        color: $cl-white;
        margin: 0;
    }

    // end video modal title
    /* ================================================ */

    /* ================================================ */
    // h. video modal transcritpion link

    &__transcription {
        font-size: 1rem;
    }

    // end video modal transcription link
    /* ================================================ */
}

// end video modal
/* //////////////////////////////////////////////// */
