/*
    1. map bubble
        a. header
        b. bodywrapper
        c. body
        d. footer section
*/

/* //////////////////////////////////////////////// */
// 1. map bubble

.map-bubble {
    width: 280px;
    position: relative;

    p {
        margin: 0;
    }

    /* ================================================ */
    // a. header

    &__header {
        position: relative;
        width: 100%;
        height: 130px;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @media screen and (min-width: $tablet) {
            height: 169px;
        }
    }

    &__title-link {
        &--no-header {
            width: calc(100% - 50px);
            display: block;
        }
    }
 
    // end header
    /* ================================================ */

    /* ================================================ */
    // b. body wrapper

    &__wrapper {
        padding: 20px 16px;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 24px;
        max-height: 354px;
        overflow: auto;
        max-height: 280px;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background: $gr-white;
            width: calc(100% - 15px);
            height: 56px;
            pointer-events: none;
        }

        a {
            position: relative;
            display: block;
            word-break: break-word;
            width: calc(100% - 50px);

            &:not(.map-bubble__title-link)::after {
                content: "";
                position: absolute;
                top: 50%;
                right: -20px;
                transform: translateY(-50%);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M12.4444 12.4444H1.55556V1.55556H7V0H1.55556C0.692222 0 0 0.7 0 1.55556V12.4444C0 13.3 0.692222 14 1.55556 14H12.4444C13.3 14 14 13.3 14 12.4444V7H12.4444V12.4444ZM8.55556 0V1.55556H11.3478L3.70222 9.20111L4.79889 10.2978L12.4444 2.65222V5.44444H14V0H8.55556Z' fill='%230B70B8'/%3E%3C/svg%3E");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 14px;
                height: 14px;
            }
        }

        a.map-bubble__town-link {
            z-index: 2;
            display: initial;
        }

        @media screen and (min-wdith: $tablet) {
            height: 354px;
        } 
    }

    &__opportunities {
        font-weight: 700;
    }

    // end body wrapper
    /* ================================================ */

    /* ================================================ */
    // c. body

    &__body {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 12px;
        word-break: break-word;
        padding-bottom: 8px;
    }

    &__title {
        margin: 0;
        font-family: "Gotham Rounded A", "Gotham Rounded B", sans-serif;
        font-size: $ft-text-static;
        font-weight: 400;
    }

    &__title-link {
        text-decoration: none;
        font-weight: 500;
    }

    &__m-rating {
        font-weight: 700;
    }

    // end body
    /* ================================================ */

    /* ================================================ */
    // d. footer section

    &__footer {
        padding: 8px 0;
        border-top: 1px solid $cl-light-grey-accent;
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
            position: relative;
            //display: block;
            max-width: fit-content;

            &:not(.map-bubble__title-link)::after {
                content: "";
                position: absolute;
                //top: 50%;
                right: -20px;
                bottom: -5px;
                transform: translateY(-50%);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M12.4444 12.4444H1.55556V1.55556H7V0H1.55556C0.692222 0 0 0.7 0 1.55556V12.4444C0 13.3 0.692222 14 1.55556 14H12.4444C13.3 14 14 13.3 14 12.4444V7H12.4444V12.4444ZM8.55556 0V1.55556H11.3478L3.70222 9.20111L4.79889 10.2978L12.4444 2.65222V5.44444H14V0H8.55556Z' fill='%230B70B8'/%3E%3C/svg%3E");
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                width: 14px;
                height: 14px;
            }
        }

        //a {
            //padding-bottom: 8px;
        //}
    }

    // end footer section
    /* ================================================ */

    @media screen and (min-width: $tablet) {
        //height: 553px;
        width: 354px;
    }
}

// end map bubble
/* //////////////////////////////////////////////// */
