/*
    this modifies the already created "map-bubbles.scss"

    1. map listings
        a. wrapper
        b. header
        c. body
        d. footer
*/
/* //////////////////////////////////////////////// */
// 1. map listings

.map-listings {
    margin-top: 20px;
    display: grid;
    gap: 20px;

    .map-bubble[data-listing-active="false"] {
        display: none;
    }

    .map-bubble {
        background-color: $cl-light-grey;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        a {
            svg {
                margin-left: 10px;
            }
        }

        p {
            //display: flex;
            //flex-direction: column;
            //gap: 8px;
        }

        /* ================================================ */
        // a. wrapper

        &__wrapper {
            max-height: unset;
            width: 100%;

            &::after {
                background: unset;
            }
        }

        // end wrapper
        /* ================================================ */

        /* ================================================ */
        // b. header

        &__header {
            height: 185px;

            @media screen and (min-width: 1030px) {
                height: 100%;
                width: 180px;
                flex-shrink: 0;
            }
        }

        // end header
        /* ================================================ */

        /* ================================================ */
        // c. body

        &__body {
            gap: 20px;
        }

        &__details {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        // end body
        /* ================================================ */

        /* ================================================ */
        // d. footer

        &__footer {
            border: none;
            padding: unset;
            margin-top: 20px;

            .map-bubble__title {
                font-weight: 600;
            }

            p {
                margin-bottom: 8px;
            }
        }

        @media screen and (min-width: 1030px) {
            display: flex;
            flex-direction: row;
            width: min(620px, 100%);
        }

        // end footer
        /* ================================================ */
    }

    @media screen and (min-width: 1030px) {
        grid-template-columns: repeat(2, minmax(150px, 1fr));
        gap: 40px;
        margin-top: 40px;
    }
}

// end map listings
/* //////////////////////////////////////////////// */
