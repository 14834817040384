/*
 * Mobile layout
 */

body {
    z-index: 1;
}


/*
 * Tablet layout
 */

@media screen and (min-width: $tablet){
    body {
        z-index: 2;
    }
}


/*
 * Desktop layout
 */

@media screen and (min-width: $desktop){
    body {
        z-index: 3;
    }
}
