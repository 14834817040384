/*
    1. cta
        a. cta panels
        b. cta image
        c. cta title
        d. cta description
        e. cta link
*/

/* //////////////////////////////////////////////// */
// 1. cta
.page-cta {
    posiiton: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    font-family: "Gotham Rounded A", "Gotham Rounded B", sans-serif;
    padding-bottom: 60px;

    /* ================================================ */
    // a. cta panels

    &__panel {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    // end cta panels
    /* ================================================ */

    /* ================================================ */
    // b. cta image

    &__image {
        object-fit: contain;
        object-position: center;
    }

    // end cta image
    /* ================================================ */

    /* ================================================ */
    // c. cta title

    h2.page-cta__title {
        font-size: $ft-title;
        font-style: normal;
        font-weight: 325;
        color: $cl-royal-blue;
        margin: 0;
        padding: 0;
        margin-bottom: 16px;
        line-height: 130%;

        @media screen and (min-width: $tablet) {
            line-height: 40px;
        }
    }

    // end cta title
    /* ================================================ */

    /* ================================================ */
    // d. cta description

    &__description {
        font-size: $ft-text-static;
        line-height: 30px;
        color: $cl-dark-grey;
        font-family: "Gotham A","Gotham B",sans-serif;
        font-style: normal;
        font-weight: 325;
        margin: 0;
        padding: 0;
        margin-bottom: 20px;
    }

    // end cta description
    /* ================================================ */

    /* ================================================ */
    // e. cta link

    &__cta-link {
        align-self: flex-start;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;
    }

    // end cta link
    /* ================================================ */

    @media screen and (min-width: $tablet) {
        flex-direction: row;
    }
}
// end cta
