@import "./filters.scss";
@import "./map-bubble.scss";
@import "./map-lisitings.scss";

/*
    1. map component

    - google overrides
*/

/* //////////////////////////////////////////////// */
// 1. map component

.map-module {
    padding-bottom: 60px;

    h2.map-module__title {
        font-size: $ft-title;
        font-style: normal;
        font-weight: 325;
        color: $cl-royal-blue;
        margin: 0;
        margin-bottom: 16px;

        @media screen and (min-width: $tablet) {
            margin-bottom: 20px;
        }
    }

    &__info {
        margin: 0;
        margin-bottom: 20px;
        font-size: $ft-text-static;
    }

    &__see-all-locations {
        margin-bottom: 40px;
        display: inline-block;
    }

    &__map {
        width: min(1280px, 100%);
        position: relative;
        height: 546px;
        margin: 0 auto;
        border-radius: 0;

        @media screen and (min-width: $tablet) {
            height: 720px;
        }

        @media screen and (max-height: 800px) {
            height: 450px;
        }
    }

    &__wrapper {
        position: relative;
        border: 1px solid $cl-light-grey-border;

        @media screen and (min-width: $tablet) {
            overflow: hidden;
            border-radius: 10px;
        }
    }

    &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $tr-black;
        pointer-events: none;
        opacity: 0;
        z-index: 1;
        transition: .3s;

        @media screen and (min-width: $tablet) {
            opacity: 0;
        }
    }

    &__filter-panel-toggle {
        position: absolute;
        z-index: 2;
        display: flex;
        gap: 12px;
        align-items: center;
        border-radius: 0;
        top: 0;
        left: 0;

        @media screen and (min-width: $tablet) {
            left: 29px;
            top: 20px;
            padding: 20px 30px;
            font-weight: 400;
            border-radius: 100px;
        }
    }

    // if filters are open
    &__wrapper[data-filters-open="true"] {
        .map-module__backdrop {
            opacity: 1;

            @media screen and (min-width: $tablet) {
                opacity: 0;
            }
        }
    }
}

// end map bubble
/* //////////////////////////////////////////////// */

/* //////////////////////////////////////////////// */
// - google overrides

.gm-style-iw-d,
.gm-style-iw-c,
.gm-style.gm-style-iw-c {
    padding: 0 !important;
}

.gm-ui-hover-effect {
    background-color: $cl-white !important;
    opacity: 1;
    border-radius: 100px;
    top: 10px !important;
    right: 20px !important;
    width: 44px !important;
    height: 44px !important;
    text-align: center;
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border: 1px solid $cl-light-grey-accent !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    span {
        margin: 0 !important;
        height: calc(100% - 13px) !important;
        width: calc(100% - 13px) !important;
        background-color: $cl-burnt-orange;
    }
}

.gm-style-iw-d {
    overflow: unset !important;
    max-height: unset !important;
}

.gm-style-iw.gm-style-iw-c,
.gm-style.gm-style-iw-d,
.gm-style.gm-style-iw-c {
    max-height: unset !important;
}
// end google overrides
/* //////////////////////////////////////////////// */
