/*
    1. image video cards
        a. card items
        b. thumbnail wrapper
        c. video thumbnail
        d. video play icon
        e. card thumbnail image
        f. video card timestamp
        g. card title / date wrapper
        h. video card title
*/

/* //////////////////////////////////////////////// */
// 1. image video cards

.img-video-cards {
    display: grid;
    margin: 0 auto;
    margin-bottom: 40px;
    justify-items: center;
    gap: 40px;
    background-color: $cl-light-grey-two;
    padding: 2rem 1rem 1rem 1rem;
    font-family: "Gotham A", "Gotham B", sans-serif;
    padding-bottom: 60px;

    /* ================================================ */
    // a. card items

    &__card {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 18px;
        font-weight: 400;
        font-size: 18px;

        a {
            outline-offset: 0.2rem;
            border-radius: 0.3rem;
        }
    }

    // end card items
    /* ================================================ */

    /* ================================================ */
    // b. thumbnail wrapper

    &__img-wrapper {
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        height: 189px;
        display: flex;
        align-items: center;
        justify-content: center;

        /* ================================================ */
        // c. video thumbnail

        &-video-thumb {
            cursor: pointer;

            &:focus-within {
                outline: 0.2rem solid $cl-dark-grey;
                outline-offset: 0.2rem;
            }
            
            &::before {
                content: "";
                position: absolute;
                background: $gr-light-blue;
                width: 100%;
                height: 100%;
                z-index: 2;
            }
        }

        // end video thumbnail
        /* ================================================ */
    }

    // end thumbnail wrapper
    /* ================================================ */

    /* ================================================ */
    // d. video play icon

    &__accessible-video-player {
        border: none;
        background-color: transparent;
        outline: none;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 3;
    }

    &__play-video {
        width: 42px;
        height: 42px;
        object-fit: contain;
        top: 0;
        left: 0;
    }

    // end video play icon
    /* ================================================ */

    /* ================================================ */
    // e. card thumbnail image

    &__thumbnail {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    // end card thumbnail image
    /* ================================================ */

    /* ================================================ */
    // f. video card timestamp

    &__timestamp {
        text-transform: uppercase;
        color: $cl-blue;
        font-size: 12px;
        font-weight: 600;
    }

    // end video card timestamp
    /* ================================================ */

    /* ================================================ */
    // g. card title / date wrapper

    &__info {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-top: 25px;

        &--video {
            flex-direction: column;
            align-items: flex-start;
            margin-top: unset;
        }
    }

    // end card title / date wrapper
    /* ================================================ */

    
    /* ================================================ */
    // h. video card title

    &__title {
        color: $cl-burnt-orange;
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 350;
    }

    // end video card title
    /* ================================================ */

    @media screen and (min-width: 945px) {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
}

// end image video cards
/* //////////////////////////////////////////////// */
