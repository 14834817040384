// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #4a4a4a;

// titles
$ft-title: clamp(30px, 4vw, 40px);
$ft-sub-title: clamp(18px, 2vw, 22px);
$ft-sub-sub-title: clamp(16px, 2vw, 18px);
$ft-medium: clamp(18px, 2vw, 20px);
$ft-button-static: 16px;
$ft-text-static: 18px;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// Default breakpoints
$tablet: 768px;
$desktop: 1300px;

// Colours
$cl-white: #fff;
$cl-light-grey: #F7F7F7;
$cl-light-grey-two: #F5F5F5;
$cl-dark-grey: #484848;
$cl-blue: #006FB9;
$cl-dark-blue: #3070B3;
$cl-royal-blue: #0B70B8;
$cl-burnt-orange: #C05131;
$cl-heti-blue: #00B1C1;
$cl-black: #000;
$cl-light-grey-accent: #EBEBEB;
$cl-light-grey-border: #D9D9D9;

// transparent colours
$tr-black: rgba(0, 0, 0, .7);

// gradients
$gr-blue: linear-gradient(90deg, #116EB1 0.08%, rgba(17, 110, 177, 0) 82.34%, rgba(17, 110, 177, 0) 100.04%);
$gr-light-blue: linear-gradient(360deg, #00B1C1 0%, rgba(0, 177, 193, 0.1) 51.56%, rgba(0, 177, 193, 0) 68.23%);
$gr-white: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 72.78%);
