@mixin button ($type: text, $is-round: true, $font-static: false) {
    cursor: pointer;
    border: none;
    text-decoration: none;
    font-family: "Gotham A", "Gotham B", sans-serif;
    font-weight: 400;

    @if $type == text {
        font-size: $ft-medium;
    }

    @else if $type == full {
        padding: 12px 13px;
        font-size: $ft-button-static;

        @if $is-round == true {
            border-radius: 100px;
        }
    }

    @if $font-static == true {
        font-size: $ft-button-static;
    }
}

// full buttons
.btn-burnt-orange {
    background-color: $cl-burnt-orange;
    color: $cl-white;
    @include button(full);

    &:is(:focus, :active) {
        outline: 0.2rem solid $cl-burnt-orange;
        outline-offset: 0.2rem;
    }

    @media screen and (min-width: $tablet) {
        padding: 12px 30px;
    }
}

// text buttons
.btn-text-grey {
    background-color: transparent;
    color: $cl-dark-grey;
    @include button();

    &:is(:focus, :active) {
        outline: 0.2rem solid $cl-dark-grey;
        color: $cl-dark-grey;
    }

    &:is(:hover) {
        color: $cl-dark-grey;
        text-decoration: underline;
    }
}

.btn-text-burnt-orange {
    background-color: transparent;
    color: $cl-burnt-orange;
    @include button(text, false, true);

    &:is(:focus, :active) {
        outline: 0.2rem solid $cl-burnt-orange;
        color: $cl-burnt-orange;
    }

    &:is(:hover) {
        color: $cl-burnt-orange;
        text-decoration: underline;
    }
}

.btn-blue-text {
    background-color: transparent;
    color: $cl-heti-blue;
    @include button();

    &:is(:focus, :active) {
        outline: 0.2rem solid $cl-heti-blue;
        color: $cl-heti-blue;
    }

    &:is(:hover) {
        color: $cl-heti-blue;
        text-decoration: underline;
    }
}

.btn-text-blue {
    background-color: transparent;
    color: $cl-royal-blue;
    @include button();

    &:is(:focus, :active) {
        outline: 0.2rem solid $cl-royal-blue;
        color: $cl-royal-blue;
    }

    &:is(:hover) {
        color: $cl-royal-blue;
        text-decoration: underline;
    }
}
